<template lang="pug">
    .content__main.-without-right-aside
        .promocodes
            ._head
                ._title Мои промокоды
                ._head-group
                    form._form(@submit.prevent="generatePromocodes")
                        input(type="numb" v-model="attempts" placeholder="Сообщений")._input
                        input(type="numb" v-model="count" placeholder="Промокодов")._input
                        input(type="text" v-model="newReferrer" placeholder="Имя блогера")._input
                        ui-button(size="small" text="Добавить" type="submit")
                    ._error(v-if="attemptsError") Введите количество сообщений от 1 шт.
            perfect-scrollbar._list(infinite-wrapper v-if="isEditor || isCurator")
                ._list-group
                    ._select-all(v-if="filteredPromocodes.length" @click="selectAll") Выделить все
                    input(type="numb" v-model="q" placeholder="Поиск")._input.-big
                    input(type="text" v-model="referrer" placeholder="Блогер")._input.-big
                    ._copy-all(v-if="checkedPromocodes.length" @click="copyToClip") Копировать выделенные
                    ._delete-all(v-if="checkedPromocodes.length" @click="confirmAllDelete") Удалить выбранные
                ._promocode
                    ._promocode-string Код
                    ._promocode-date Создан
                    ._promocode-referrer Блогер
                    ._promocode-used Использован
                    ._promocode-count Осталось сообщений
                ._promocode(v-for="promocode in filteredPromocodes" :class="{new: promocode.new}" :key="promocode.code" v-if="!promocode.deleted")
                    label._promocode-string(:class="{used: promocode.used}" :for="promocode.code")
                        ui-checkbox(type="checkbox" :name="promocode.code" v-bind:model.sync="promocode.checked" :checked="promocode.checked" for="promocodes")._checkbox
                        span {{ promocode.code }}
                    ._promocode-date(:title="promocode.createdAt.date.split('.')[0]") {{ showDate(promocode.createdAt.date) }}
                    ._promocode-referrer {{ promocode.referrerName }}
                    ._promocode-used
                        span(v-if="promocode.used") {{ showDate(promocode.usedAt.date) }}
                        span(v-else) —
                    ._promocode-count {{ promocode.messageAttempts }}
                        svg-icon(icon-name="cross" @click.native="confirmDelete(promocode)")._delete-icon
                infinite-loading(@infinite="getPromocodes")
                    ._message-loading(slot="spinner")
                        loading._message-loader
                    ._more-messages(slot="no-more")
                    ._no-messages(slot="no-results")
</template>

<script>
import gql from "graphql-tag";
import getCuratorPromocodesQuery from "@/graphql/queries/getCuratorPromocodes.query.graphql";
import generateCuratorPromocodes from "@/graphql/mutations/generateCuratorPromocodes.mutation.graphql";
import deleteCuratorPromocodeMutation from "@/graphql/mutations/deleteCuratorPromocode.mutation.graphql";
import UiButton from "../components/ui/ui-button/ui-button.vue";
import InfiniteLoading from "vue-infinite-loading";
import UiCheckbox from "../components/ui/ui-checkbox/ui-checkbox.vue";

export default {
    name: "Promocodes",
    components: { UiCheckbox, UiButton, InfiniteLoading },
    data() {
        return {
            promocodes: [],
            attempts: null,
            count: null,
            attemptsError: false,
            q: null,
            referrer: "",
            page: 1,
            hasMore: true,
            promocodeToDelete: null,
            newReferrer: "",
        };
    },
    computed: {
        checkedPromocodesText: state => state.checkedPromocodes.map(code => code.code).join(" "),
        checkedPromocodes: (state) =>
            state.filteredPromocodes.filter((promocode) => promocode.checked),
        filteredPromocodes: (state) =>
            state.q
                ? state.promocodes.filter((q) => q.code === state.q)
                : state.promocodes,
        promocodesToDelete: function () {
            if (!this.checkedPromocodes.length) {
                return [];
            }

            const p = [];

            this.checkedPromocodes.forEach((promocode) => {
                p.push(promocode.id);
            });

            return p;
        },
    },
    watch: {
        referrer() {
            this.promocodes = [];
            this.page = 1;
            this.hasMore = true;
            this.getPromocodes();
        },
    },
    mounted() {
        if (this.isCurator || this.isEditor) return;
        this.$router.push("/");
    },
    methods: {
        copyToClip() {
            if (window.isSecureContext) {
                navigator.clipboard.writeText(this.checkedPromocodesText);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = this.checkedPromocodesText;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand("copy");
                } catch (err) {
                    console.error("Unable to copy to clipboard", err);
                }
                document.body.removeChild(textArea);
            }
        },
        showDate(d) {
            const date = new Date(d);
            // Добавляем ведущий ноль, если число меньше 10
            const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

            // День
            const day = addLeadingZero(date.getDate());
            // Месяц (getMonth возвращает значение от 0 до 11, поэтому добавляем 1)
            const month = addLeadingZero(date.getMonth() + 1);
            // Год (получаем последние две цифры)
            const year = date.getFullYear().toString().slice(-2);
            // Часы
            const hours = addLeadingZero(date.getHours());
            // Минуты
            const minutes = addLeadingZero(date.getMinutes());

            // Формируем итоговую строку
            return `${day}.${month}.${year} ${hours}:${minutes}`;
        },
        async getPromocodes($state) {
            await this.$apollo
                .query({
                    query: gql(getCuratorPromocodesQuery),
                    variables: {
                        page: this.page,
                        limit: 100,
                        referrer_name: this.referrer,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    if (r.data.getCuratorPromocodes.list.length) {
                        this.hasMore = r.data.getCuratorPromocodes.hasMore;

                        r.data.getCuratorPromocodes.list.forEach((code) => {
                            this.$set(code, "deleted", false);
                            this.$set(code, "checked", false);
                            this.promocodes.push(code);
                        });

                        this.page++;
                        if ($state) {
                            $state.loaded();
                            if (!r.data.getCuratorPromocodes.hasMore) {
                                $state.complete();
                            }
                        }
                    } else {
                        if ($state) {
                            $state.complete();
                        }
                    }
                });
        },
        async generatePromocodes() {
            if (this.attempts <= 0) {
                return (this.attemptsError = true);
            }
            await this.$apollo
                .mutate({
                    mutation: gql(generateCuratorPromocodes),
                    variables: {
                        attempts: +this.attempts,
                        count: +this.count || 1,
                        referrer_name: this.newReferrer,
                    },
                })
                .then((r) => {
                    r.data.generateCuratorPromocodes.forEach((code) => {
                        this.$set(code, "deleted", false);
                        this.$set(code, "new", true);
                        setTimeout(() => {
                            code.new = false;
                        }, 2000);
                        this.promocodes.unshift(code);
                    });
                    this.attempts = null;
                    this.count = null;
                    this.newReferrer = "";
                });
        },
        confirmDelete(promocode) {
            this.promocodeToDelete = promocode;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите удаление",
                text: "Вы действительно хотите удалить промокод?",
                buttons: true,
                confirmText: "Удалить",
                callback: this.deletePromocode,
            });
        },
        confirmAllDelete(promocode) {
            this.promocodeToDelete = promocode;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите удаление",
                text: `Вы действительно хотите удалить ${this.checkedPromocodes.length} промокодов?`,
                buttons: true,
                confirmText: "Удалить",
                callback: this.deletePromocodes,
            });
        },
        deletePromocode() {
            this.$apollo
                .mutate({
                    mutation: gql(deleteCuratorPromocodeMutation),
                    variables: {
                        id: [+this.promocodeToDelete.id],
                    },
                })
                .then(() => {
                    this.promocodeToDelete.deleted = true;
                    this.promocodeToDelete = null;
                });
        },
        deletePromocodes() {
            this.$apollo
                .mutate({
                    mutation: gql(deleteCuratorPromocodeMutation),
                    variables: {
                        id: this.promocodesToDelete,
                    },
                })
                .then(() => {
                    this.checkedPromocodes.forEach((p) => {
                        p.checked = false;
                        p.deleted = true;
                    });
                    console.log(this.filteredPromocodes.length);
                    this.promocodes = [];
                    this.page = 1;
                    this.hasMore = true;
                    this.referrer = "";
                    this.getPromocodes();
                });
        },
        selectAll() {
            if (
                this.filteredPromocodes.length === this.checkedPromocodes.length
            ) {
                console.log(
                    this.filteredPromocodes.length,
                    this.checkedPromocodes.length,
                );
                this.filteredPromocodes.forEach((p) => {
                    p.checked = false;
                });
                return;
            }
            this.filteredPromocodes.forEach((p) => {
                p.checked = true;
            });
        },
    },
};
</script>

<style lang="scss">
.promocodes {
    $root: &;

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 17px;
        font-weight: bold;
        color: #fff;
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        position: relative;
        height: 30.5px;
        padding: 0 10px;
        max-width: 120px;
        color: #fff;
        transition: 0.4s;
        border: 0;
        background: #323232;
        z-index: 1;
        margin-right: 5px;
        text-align: center;

        &_big {
            width: 220px;
            max-width: 220px;
            text-align: left;
        }
    }

    &__error {
        color: red;
        font-size: 12px;
        margin-top: 7px;
    }

    &__list {
        margin-top: 20px;
        overflow: scroll;
        height: calc(100vh - 200px);
    }

    &__promocode {
        display: flex;
        align-items: center;
        padding: 10px;
        transition: 0.4s;

        &.new {
            background: rgba($gold, 0.1);
        }

        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }

        &:first-child {
            #{$root} {
                &__promocode-date,
                &__promocode-string,
                &__promocode-used,
                &__promocode-count {
                    color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

    &__promocode-date {
        width: 20%;
        color: #fff;
        text-align: center;
    }

    &__promocode-referrer {
        width: 20%;
        color: #fff;
        text-align: center;
    }

    &__promocode-string {
        display: flex;
        align-items: center;
        color: $gold;
        width: 20%;

        &.used {
            text-decoration: line-through;
            color: rgba(255, 255, 255, 0.2);
        }
    }

    &__promocode-used {
        width: 20%;
        color: #fff;
        margin-left: 10px;
        text-align: center;
    }

    &__promocode-count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 20%;
        color: #fff;
        text-align: center;
    }

    &__delete-icon {
        width: 20px;
        height: 20px;
        margin-left: 30px;
        fill: red;

        &:hover {
            cursor: pointer;
        }
    }

    &__message-loading {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    &__message-loader {
        position: initial;
    }

    &__list-group {
        display: flex;
        align-items: center;
    }

    &__select-all {
        color: #fff;
        margin-right: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    &__copy-all {
        color: $gold;
        margin-left: auto;
    }

    &__delete-all {
        color: red;
        margin-left: auto;
    }
}
</style>
